import React, { useState, useRef } from 'react';
import coverlogo from '../../resources/coverlogo.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dropdownTimeout = useRef(null); // Reference for timeout

  const handleMouseEnter = () => {
    if (dropdownTimeout.current) {
      clearTimeout(dropdownTimeout.current);
    }
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    dropdownTimeout.current = setTimeout(() => {
      setDropdownOpen(false);
    }, 200); // Adjust timeout as necessary
  };

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <nav className="bg-black">
      <div className="max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6 relative h-24 md:h-24">
        <div className="absolute left-1/2 transform -translate-x-1/2 md:static md:transform-none md:left-0 h-auto w-auto">
          <img
            src={coverlogo}
            alt="coverlogo"
            className="h-32 md:h-28 cursor-pointer"
            onClick={() => window.location.href = '/'}
          />
        </div>

        <div className="md:hidden relative z-10">
          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="text-3xl text-white hover:bg-gray-900 px-2 py-2 rounded" 
          >
            ☰
          </button>
        </div>

        <div className="hidden md:flex items-center justify-between w-full ml-8">
          <div className="flex space-x-4">
            <button onClick={() => window.location.href = '/'} className="text-sm md:text-lg lg:text-xl text-white font-serif hover:bg-gray-900 px-3 lg:px-4 py-2 rounded">
              Forsíða
            </button>

            <div
              className="relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <button className="text-sm md:text-lg lg:text-xl text-white font-serif hover:bg-gray-900 px-3 lg:px-4 py-2 rounded flex items-center">
                Vörur <ArrowDropDownIcon />
              </button>
              {dropdownOpen && (
                <div className="absolute left-0 mt-2 bg-black shadow-lg rounded w-48 z-20">
                  <a href="/braud" className="block px-4 py-2 text-white font-serif hover:bg-gray-900">Brauð</a>
                  <a href="/runstykki" className="block px-4 py-2 text-white font-serif hover:bg-gray-900">Rúnstykki</a>
                  <a href="/bakkelsi" className="block px-4 py-2 text-white font-serif hover:bg-gray-900">Bakkelsi</a>
                  <a href="/smurt" className="block px-4 py-2 text-white font-serif hover:bg-gray-900">Smurt</a>
                </div>
              )}
            </div>
            <button onClick={() => window.location.href = '/kokur'} className="text-sm md:text-lg lg:text-xl text-white font-serif hover:bg-gray-900 px-3 lg:px-4 py-2 rounded">Kökur</button>
            <button onClick={() => window.location.href = '/pantanir'} className="text-sm md:text-lg lg:text-xl text-white font-serif hover:bg-gray-900 px-3 lg:px-4 py-2 rounded">Pantanir</button>
          </div>

          <div className="flex space-x-4 ml-10">
            <button onClick={() => window.location.href = '/hafa-samband'} className="text-sm md:text-lg lg:text-xl text-white font-serif hover:bg-gray-900 px-3 lg:px-4 py-2 rounded">Hafa samband</button>
            <button onClick={() => window.location.href = '/umokkur'} className="text-sm md:text-lg lg:text-xl text-white font-serif hover:bg-gray-900 px-3 lg:px-4 py-2 rounded">Um okkur</button>
          </div>
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="md:hidden mt-4 space-y-2">
          <button className="block text-white font-serif hover:bg-gray-900 px-4 py-3 rounded w-full text-left">Forsíða</button>
          <div>
            <button
              onClick={handleDropdownClick}
              className="block text-white font-serif hover:bg-gray-900 px-4 py-3 rounded w-full text-left"
            >
              Vörur <ArrowDropDownIcon />
            </button>
            {dropdownOpen && (
              <div className="bg-gray-900 shadow-lg rounded mt-1">
                <a href="/braud" className="text-white font-serif block px-12 py-2 text-black hover:bg-gray-800">Brauð</a>
                <a href="/runstykki" className="text-white font-serif block px-12 py-2 text-black hover:bg-gray-800">Rúnstykki</a>
                <a href="/bakkelsi" className="text-white font-serif block px-12 py-2 text-black hover:bg-gray-800">Bakkelsi</a>
                <a href="/smurt" className="text-white font-serif block px-12 py-2 text-black hover:bg-gray-800">Smurt</a>
              </div>
            )}
          </div>
          <button onClick={() => window.location.href = '/kokur'} className="block text-white font-serif hover:bg-gray-900 px-4 py-3 rounded w-full text-left">Kökur</button>
          <button onClick={() => window.location.href = '/pantanir'} className="block text-white font-serif hover:bg-gray-900 px-4 py-3 rounded w-full text-left">Pantanir</button>
          <button onClick={() => window.location.href = '/hafa-samband'} className="block text-white font-serif hover:bg-gray-900 px-4 py-3 rounded w-full text-left">Hafa samband</button>
          <button onClick={() => window.location.href = '/umokkur'} className="block text-white font-serif hover:bg-gray-900 px-4 py-3 rounded w-full text-left">Um okkur</button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
