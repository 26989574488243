import React from 'react';
import { useState } from 'react';
import ImageSlider from './slider';
import kaka1 from '../../resources/pontunarkokur/bjossi1.jpg';
import kaka2 from '../../resources/pontunarkokur/bjossi2.jpg';
import kaka3 from '../../resources/pontunarkokur/bjossi3.jpg';
import kaka4 from '../../resources/pontunarkokur/bjossi4.jpg';
import kaka5 from '../../resources/pontunarkokur/bjossi5.jpg';
import kaka6 from '../../resources/pontunarkokur/bjossi6.jpg';
import kaka7 from '../../resources/pontunarkokur/bjossi7.jpg';
import kaka8 from '../../resources/pontunarkokur/bjossi8.jpg';
import kaka9 from '../../resources/pontunarkokur/bjossi9.jpg';
import kaka10 from '../../resources/pontunarkokur/bjossi10.jpg';
import marsipan10 from '../../resources/pontunarkokur/marsipan10.JPG';
import marsipan1 from '../../resources/pontunarkokur/marsipan1.JPG';
import marsipan2 from '../../resources/pontunarkokur/marsipan2.JPG';
import marsipan3 from '../../resources/pontunarkokur/marsipan3.JPG';
import marsipan4 from '../../resources/pontunarkokur/marsipan4.JPG';
import heidda1 from '../../resources/pontunarkokur/heidda1.JPG';
import heidda2 from '../../resources/pontunarkokur/heidda2.JPG';
import heidda3 from '../../resources/pontunarkokur/heidda3.JPG';
import heidda4 from '../../resources/pontunarkokur/heidda4.JPG';
import heidda5 from '../../resources/pontunarkokur/heidda5.JPG';
import heidda6 from '../../resources/pontunarkokur/heidda6.JPG';
import heidda7 from '../../resources/pontunarkokur/heidda7.JPG';
import kaka16 from '../../resources/pontunarkokur/afmælis_minecraft.jpeg';
import kaka17 from '../../resources/pontunarkokur/afmæliskaka.jpeg';

const cakes = [
    {
        id: 1,
        name: "Marsipantertur",
        sizes: "12, 16, 20, 30, 40, 50 og 60 manna",
        fillings: ["Sælgætis m/marsbitum", 
                "Jarðaberja", 
                "Sherrý", 
                "Súkkulaði", 
                "Karamellu/krókant",
                "Hindberja",
                "Bananasplitt",
                "Karamellu-daim" 
        ],
        images: [
            marsipan10,
            marsipan1,
            marsipan2,
            marsipan3,
            marsipan4,
            kaka3,
            kaka7,
            kaka8,
            kaka9,
            kaka10,
        ],
    },
    {
        id: 2,
        name: "Smjörkremskökur",
        sizes: "6-8, 12-15, 20-25, 25-30, 35-40 manna",
        botnar: ["Súkkulaði", "Vanillu", "Karamellu"],
        smjorkrem: ["Súkkulaði", "Vanillu", "Saltkaramellu", "Oreo", "Jarðaberja", "Hindberja", "Dumle", "Biscoff"],
        images: [
            heidda1,
            heidda2,
            heidda3,
            heidda4,
            heidda5,
            heidda6,
            heidda7
        ],
    },
    {
      id: 4,
      name: "Afmælistertur",
      sizes: "20, 30 og 40 manna",
      images: [
          kaka16,
          kaka17
      ],
    },
];

const CakeComponent = () => {
  return (
    <div className="flex flex-col space-y-8 p-4 md:p-8">
        <div className="text-center py-8 bg-black rounded-lg p-3">
            <h1 className="text-3xl font-bold text-white mb-4 font-serif">Kökur</h1>
            <p className="text-xl text-white font-serif">Við bjóðum upp á mikið úrval af kökum í pantanir</p>
        </div>
      {cakes.map(cake => (
        <div key={cake.id} className="flex flex-col md:flex-row bg-black border rounded-lg shadow-lg p-4 space-y-4 md:space-y-0 md:space-x-4">
          <div className="w-full md:w-1/2">
            <h2 className="text-white text-xl md:text-2xl font-serif font-bold mb-2">{cake.name}</h2>
            <p className="text-white font-serif text-base md:text-lg text-gray-700 mb-4">Stærðir: {cake.sizes}</p>

            {/* Render the Fillings box only if the cake has fillings */}
            {cake.fillings && (
              <div className="mb-4">
                <h3 className="text-white font-serif font-bold">Fyllingar:</h3>
                <ul className="text-white font-serif list-disc list-inside">
                  {cake.fillings.map((filling, index) => (
                    <li key={index}>{filling}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Render the Botnar box only if the cake has botnar */}
            {cake.botnar && (
              <div className="mb-4">
                <h3 className="text-white font-serif font-bold">Botnar:</h3>
                <ul className="text-white font-serif list-disc list-inside">
                  {cake.botnar.map((botn, index) => (
                    <li key={index}>{botn}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Render the Smjorkrem box only if the cake has smjorkrem */}
            {cake.smjorkrem && (
              <div className="mb-4">
                <h3 className="text-white font-serif font-bold">Smjörkrem:</h3>
                <ul className="text-white font-serif list-disc list-inside">
                  {cake.smjorkrem.map((smjorkrem, index) => (
                    <li key={index}>{smjorkrem}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="w-full md:w-1/2">
            <ImageSlider images={cake.images} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CakeComponent;
